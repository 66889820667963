@import "src/theme";

.image-gallery {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto;
  grid-gap: 0.5em;

  &_main-image {
    width: 100%;
    height: 400px;

    @include LessThanLaptop {
      height: 300px;
    }

    @include LessThanTablet {
      height: 250px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &_gallery {
    display: grid;
    overflow: visible;
    width: 100%;
    padding: 1em 0;
    grid-template-columns: auto 1fr auto;
    grid-template-rows: 1fr;
    place-items: center flex-start;
    grid-gap: 1em;

    &-images {
      @include CustomScrollBar();
      @include FlexRow(flex-start, center, 1em, 0);
      overflow-x: auto;
      width: 100%;
      user-select: none;

      @include MoreThanTablet {
        @include FlexRow(flex-start, center, 1em, 0);
      }
    }

    .control {
      display: grid;
      height: 100%;
      padding: 1em 0.25em;
      cursor: pointer;
      transition: all 0.125s ease-in-out;
      border: none;
      background: RGBA($light-shade-darker-rgb, 0.2);
      place-items: center;

      &:hover {
        background: RGBA($light-shade-darker-rgb, 0.5);
      }

      &:active {
        background: RGBA($light-shade-darker-rgb, 0.75);
      }

      &[disabled] {
        cursor: not-allowed;
        pointer-events: none;
        color: $light-shade-darker-rgb;
        background: RGBA($light-shade-darker-rgb, 0.1);

      }
    }
  }

  &_thumbnail {
    position: relative;
    width: fit-content;
    height: 85px;
    cursor: pointer;
    outline: none;
    aspect-ratio: 1 / 1.2;
    object-fit: scale-down;

    &.active {
      outline: 2px solid $light-shade-darker;
      outline-offset: -2px;
    }
  }

}


