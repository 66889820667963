@import "src/theme";

:host {
  width: 100%;
}

.document-card {
  width: 100%;
  background: rgba(128, 128, 128, 0.1);

  .content {
    @include FlexRow(flex-start, center, 0.5rem, 1rem);
    width: 100%;

    .name {
      font-size: 1.25rem;
      font-weight: 500;
    }

    a {
      text-decoration: none;
    }
  }
}
