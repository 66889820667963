@import "src/theme";

.featured-products {
  width: 100%;

  .title {
    font-size: 2rem;
    font-weight: 700;
    width: 100%;
  }

  .content {
    width: 100%;
    padding: 1rem 0;

    .product-list {
      @include FlexRow(flex-start, stretch, 2em, 0 0 1em);
      @include CustomScrollBar();
      overflow-x: auto;
      list-style: none;

      .product-item {
        flex: 1 0 clamp(300px, 100%, 350px);

        @include MobileView {
          flex: 1 0 100%;
        }
      }
    }
  }
}
