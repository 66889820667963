@import "src/theme";

.breadcrumb-container {
  width: 100%;
  background: $light;
  @include FlexRow(flex-start, center, 1rem, 4rem 10% 1rem);

  @include LessThanMidLaptop {
    padding: 3rem;
  }

  @include LessThanTablet {
    padding: 2rem 1rem 1rem;
  }
}

.breadcrumb {
  @include FlexRow(flex-start, center, 0, 0);
  flex-wrap: wrap;
  width: 100%;
  background: $light;
  row-gap: 0.5em;

  // Space out each breadcrumb item
  .breadcrumb-item {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    color: $accent;

    // Styling for clickable links
    a {
      transition: color 0.3s ease;
      text-decoration: none;
      color: $accent;

      &:hover {
        font-weight: 600;
        color: $accent;
      }
    }

    // Adjustments for active item (last breadcrumb)
    &.active {
      font-weight: bold;
      color: $primary;

      a {
        text-decoration: none;
        pointer-events: none;
        color: $primary; // Make sure it's not clickable
      }
    }

    // Separator between breadcrumb items
    & + .breadcrumb-item::before {
      margin: 0 8px;
      content: ">";
      color: $accent;
    }
  }
}

