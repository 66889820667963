@import 'src/theme';

.services {
	width: 100%;
	min-height: 100vh;
	padding: 5% 10% 1%;

	h1 {
		font-size: 1.75em;
		width: 100%;
	}

	@include LessThanLaptop {
		padding: 5% 2rem;

		h1 {
			text-align: center;
		}
	}
}
