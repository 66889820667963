@import "src/theme";

.products-category-page {

  .content {
    width: 100%;
  }

  .subTitle {
    padding-top: 0.5rem;
    color: RGBA($dark, 0.5);
  }

}
