@import "src/theme";

.page {
  @include FlexColumn(flex-start, flex-start, 1rem, 2rem 10%);
  width: 100%;
  height: 100%;
  min-height: unset;

  &.has-min {
    min-height: 100vh;
  }

  .header {
    @include FlexRow(flex-start, flex-start, 0.5rem, 1);
    width: 100%;
    padding-bottom: 1rem;

    &_content {
      @include FlexColumn(flex-start, flex-start, 0.5rem, 0);
    }

    .title {
      font-size: 2em;
    }

    .subTitle {
      font-size: 1.5em;
      font-weight: 600;
      color: RGBA($dark, 0.5);
    }

    @include LessThanTablet {
      flex-direction: column;

      .title {
        font-size: 1.25em;
      }

      .subTitle {
        font-size: 1em;
      }
    }
  }

  .content {
    width: 100%;
  }

  @include LessThanMidLaptop {
    padding: 2rem 3rem;
  }

  @include LessThanTablet {
    padding: 1rem;
  }
}
