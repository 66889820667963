@import 'src/theme/mixin';
@import 'src/theme/variables';
@import 'src/theme/common';

footer {
	display: grid;
	width: 100%;
	padding: 4em 2em;
	background: $light;
	box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-areas: 'meta links acc contact';
	place-items: flex-start center;

	@include LessThanMidLaptop {
		padding: 2rem;
		grid-template-areas: 'links links' 'meta contact' 'acc acc';
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto 1fr;
		grid-row-gap: 2em;

		.footer-section {
			&.links-section {
              @include FlexRow(center, center, 1rem, 0);
				width: 100%;
				margin-bottom: 2em;
				border-right: none !important;
				border-bottom: 1px solid $light-shade-darker;
				border-left: none !important;

				.links {
                  @include FlexRow(space-evenly, center, 2rem, 0);
				}
			}

			&.accredition {
				padding-top: 1rem;
				border-top: 1px solid $light-shade-darker;
				border-left: none;

				.data {
					flex-direction: row;

					.images {
						padding: 0;
					}

					.accredition-image {
						width: 75px;
					}
				}
			}
		}
	}

	@include LessThanLaptop {
		grid-template-areas: 'meta' 'acc';
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
		grid-row-gap: 3rem;

		.footer-section {
			width: 100%;

			&.company-data, &.accredition {
				padding-top: 1rem;
			}

			.company-logo {
				width: 50%;
			}

			&.links-section {
				display: none;

				.links {
					gap: 0;
				}
			}

			&.location {
				display: none;
			}

			&.contact-forms {
				display: none;
			}
		}
	}

	@include LessThanTablet {
		padding: 1rem;

		.footer-section {
			padding-right: 0 !important;
			padding-left: 0 !important;

			&.accredition {
				.data {
					flex-direction: column;
				}
			}
		}

	}

	.footer-section {
      @include FlexColumn(flex-start, flex-start, 1em, 0 2em);
		height: 100%;

		.a-tag-link {
          @include FlexRow(flex-start, center, 0.5rem, 0);
			font-size: 1.1rem;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.inline {
          @include FlexRow(flex-start, flex-start, 0.5rem, 0);
		}

		&.company-data {
			gap: 2em;
			grid-area: meta;
		}

		.company-logo {
			width: 70%;
		}

		&.links-section {
			align-items: center;
			width: 100%;
			border-left: 1px solid $light-shade-darker;
			grid-area: links;

			.title {
				font-size: 1.2rem;
				font-weight: 600;
				text-transform: uppercase;
				color: $dark;
			}
		}

		&.location {
			grid-area: location;
		}
	}


	.links {
      @include FlexColumn(space-evenly, center, 0, 0);
		height: 100%;
		list-style: none;

		@media screen and (width < 1024px) {
			gap: 0.5em;
		}

		@media screen and (width < 768px) {
			display: none;
		}


		.link {
          @include FlexRow(center, center, 1em, 0.5em 1em);
			font-size: 1.1em;
			font-weight: 500;
			cursor: pointer;
			transition: all 0.125s ease-in-out;
			text-decoration: none;

			&.active {
				font-weight: 600;
				color: $primary;
			}

			&:hover {
				background-color: RGBA($light-shade, 1);
			}

			&:active {
				background-color: RGBA($light-shade-darker, 0.5);
			}
		}
	}

	.map {
		width: 100%;
		border-radius: 0.5em;
	}

	.line {
      @include FlexRow(flex-start, center, 0.5em, 0)
	}

	.accredition {
		border-left: 1px solid $light-shade-darker;
		grid-area: acc;

		.images {
			@include FlexRow(flex-start, center, 1rem, 1rem 0);
		}

		.title {
			font-size: 1.5rem;
			font-weight: 900;
			color: $dark;
		}

		.subTitle {
			font-size: 1.2rem;
			color: $accent;
		}

		.data {
			@include FlexColumn(flex-start, flex-start, 1rem, 0);
			width: 100%;
		}


		&-image {
			width: 100px;
			transition: all $x-fast $basic-curve;
			aspect-ratio: 1;

			&:hover {
				$scale-factor: 1.1;
				transform: scale3d($scale-factor, $scale-factor, $scale-factor);
			}
		}
	}

	.contact-forms {
		border: none;
		border-left: 1px solid $light-shade-darker;
		border-radius: 0;
		background: transparent;
		grid-area: contact;

		.title {
			font-size: 1.5rem;
			font-weight: 900;
			text-align: left;
			color: $dark;
		}

		form {
			gap: 0.5rem;

			button {
				margin-top: 2rem;
			}
		}
	}

}
