@import "src/theme";

.home {
	position: relative;
	width: 100%;
	height: 100%;
	@include FlexColumn(flex-start, center, 10rem, 0 0 5rem 0);

	@include LessThanTablet {
		gap: 5rem;
	}
}

.section {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 0 10%;

	.header {
		padding-bottom: 2rem;

		.title {
			font-size: 2em;
		}

		.subTitle {
			font-size: 1.5em;
		}
	}
}
