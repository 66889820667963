@import "src/theme";

.no-data {
  @include FlexColumn(center, center, 2em, 2em);
  width: 100%;
  text-align: center;

  h1 {
    font-size: 2rem;
  }
}
