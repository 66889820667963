@import 'src/theme/mixin';
@import 'src/theme/variables';

.service-section {
  @include FlexColumn(flex-start, flex-start, 1em, 0 0 2em 0);
	margin-bottom: 2em;
	border-bottom: 1px solid RGBA($accent-rgb, 0.2);

	.title-loader {
		width: 10%;
	}

	.description-loader {
		width: 100%;
	}
}
