@import 'mixin';
@import 'variables';

$form-gap: 2rem;

.full {
	flex: 1 1 100% !important;
}

.contact-forms {
	@include FlexColumn(flex-start, center, 2em, 2em);
	width: 100%;
	height: 100%;
	border: 1px solid RGBA($light-shade-darker-rgb, 0.5);
	background-color: $light;


	.title {
		font-size: 36px;
		font-weight: 600;
		line-height: 42px;
		width: 100%;
		text-align: center;
	}

	.subTitle {
		font-size: 20px;
		font-weight: 500;
		line-height: 23px;
		width: 100%;
		text-align: center;
	}

	form {
		@include FlexRow(flex-start, center, $form-gap, 0);
		flex: 1 0 auto;
		flex-wrap: wrap;
		width: 90%;
		text-align: left;

		fieldset {
			position: relative;
			@include FlexColumn(flex-start, flex-start, 0.25em, 0);
			flex: 1 1 400px;
			border: none;
			outline: none;

			label {
				font-size: 16px;
				font-weight: 600;
				line-height: 24px;
				letter-spacing: 1px;
				color: $accent;

				sup {
					color: $primary;
				}
			}

			input, textarea, select {
				font-size: 16px;
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				min-height: 40px;
				padding: 1em;
				transition: background-color 0.125s ease-in-out;
				border: none;
				background: RGBA($light-shade-darker-rgb, 0.15);

				&::placeholder {
					color: $light-shade-darker;
				}

				&:active, &:focus, &:focus-within, &:focus-visible {
					border-bottom: 2px solid $accent;
					outline: none;
					background-color: RGBA($accent-rgb, 0.1);
				}
			}

			textarea {
				min-height: 200px;
			}
		}
	}
}


//&:before {
//	position: absolute;
//	z-index: 2;
//	bottom: 0;
//	left: 0;
//	width: 100%;
//	height: 2px;
//	content: '';
//	transition: transform 0.25s ease-in-out;
//	transform: translateX(-101%);
//	background-color: $primary;
//}
//
//
//&:focus-within:before {
//	transform: translateX(0);
//}
