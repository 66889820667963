@import "src/theme";

.cart-item {
  @include FlexRow(flex-start, flex-start, 1em, 1em);
  width: 100%;
  list-style: none;
  border: 1px solid $light-shade-darker;

  .image-section {
    height: 100px;
    aspect-ratio: 1;

    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @include LessThanTablet {
    .image-section {
      display: none;
    }
  }

  .label {
    font-weight: 600;
    color: $accent;
  }

  .info-section {
    flex: 1 1 auto;
    @include FlexColumn(flex-start, flex-start, 0.5em, 0);

    .name {
      font-size: 1.5em;
      font-weight: 600;
      color: $dark;
    }

    .category {
      font-size: 1.2em;
      font-weight: 600;
      color: RGBA($dark-rgb, 0.5);
    }

    @include LessThanLaptop {
      .name {
        font-size: 1.2em;
      }

      .category {
        font-size: 1em;
      }
    }

    .controls {
      @include FlexRow(flex-start, center, 0.5em, 0);

      .control {
        width: 30px;
        height: 30px;
        cursor: pointer;
        border: none;
        background: RGBA($light-shade-darker-rgb, 0.2);

        &:hover {
          background: RGBA($light-shade-darker-rgb, 0.5);
        }

        &:active {
          background: RGBA($light-shade-darker-rgb, 0.75);
        }
      }

      .input-value {
        font-weight: 600;
        height: 30px;
        padding: 0 0.5em;
        border: 2px solid RGBA($accent-rgb, 0.5);
        outline: none;
      }
    }

    .product-table {
      width: 100%;

      thead, tr > td:first-of-type {
        font-size: 1.1rem;
        font-weight: 600;
        background: RGBA($light-shade-darker, 0.5);
      }

      tr {
        border-bottom: 1px solid $accent-rgb;

        &:hover {
          background: RGBA($light-shade-darker, 0.15);
        }
      }

      td {
        padding: 0.25em;
        text-align: center;

        &.small {
          width: 10px;
        }
      }

      @include LessThanLaptop {
        margin-bottom: 2rem;
      }
    }
  }
}
