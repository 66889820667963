@import 'mixin';

:root {
  --primary: #e31837;
  --primary-shade: #ad122a;
  --accent: #222327;
  --dark: #000000;
  --light: #ffffff;
  --light-shade-darker: #b2b2b4;
  --light-shade: #f2f2f2;

  --primary-rgb: rgb(227, 24, 55);
  --primary-shade-rgb: rgb(173, 18, 42);
  --accent-rgb: rgb(34, 35, 39);
  --dark-rgb: rgb(0, 0, 0);
  --light-rgb: rgb(255, 255, 255);
  --light-shade-darker-rgb: rgb(178, 178, 180);
}

.spacer {
  flex: 1 1 auto;
}

a, a:visited, a:active {
  text-decoration: none;
  color: inherit;
}

.heroicon-20 {
  width: 20px;
  height: 20px;
}

.heroicon-16 {
  width: 16px;
  height: 16px;
}

.heroicon-24 {
  width: 24px;
  height: 24px;
}

.heroicon-32 {
  width: 32px;
  height: 32px;
}

.color-light-shade-darker {
  color: var(--light-shade-darker);
}

.color-light {
  color: var(--light);
}

.color-primary {
  color: var(--primary);
}

.text-content-section {
  @include FlexColumn(flex-start, flex-start, 1em, 0);
  width: 75%;

  .title {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
  }

  .text {
    font-size: 24px;
    line-height: 28px;
  }

  @include LessThanLaptop {
    width: 90%;

    .title {
      font-size: 36px;
      line-height: calc(56px * 0.75);
    }

    .text {
      font-size: 16px;
      line-height: calc(28px * 0.75);
    }
  }
}


.action-bar {
  @include FlexRow(flex-start, center, 0.5em, 0);
  width: 100%;

  &.right {
    justify-content: flex-end;
  }

  &.vertical {
    @include FlexColumn(flex-start, flex-start, 0.5em, 0);
  }
}

.vert {
  width: 2px;
  height: 100%;
  background-color: rgba(34, 35, 39, 0.1);
}



.laptop {
  @include LessThanLaptop {
    display: none !important;
  }
}

.tablet {
  @include MoreThanTablet {
    display: none !important;
  }
}

.mobile {
  @include MoreThanMobile {
    display: none !important;
  }
}


.half {
  flex: 1 1 500px;
}

.full {
  flex: 1 1 100%;
}
