@mixin white-gradient {
	background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
}

.carousel-container {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 3em 0;

	&::before,
	&::after {
		@include white-gradient;
		position: absolute;
		z-index: 2;
		width: 200px;
		height: 100%;
		content: '';
	}

	&::after {
		top: 0;
		right: 0;
		transform: rotateZ(180deg);
	}

	&::before {
		top: 0;
		left: 0;
	}


	.carousel {
		position: relative;
		display: flex;
		align-items: stretch;
		flex-flow: row nowrap;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
		list-style: none;
		animation: animate-left 40s infinite linear;
		gap: 2rem;
		grid-template-rows: 1fr;

	}
}


@keyframes animate-left {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc((-200px - 2rem) * 16));
	}
}
