@import "src/theme";

.loading-component {
  @include FlexColumn(center, center, 1em, 0);
  width: 100%;
  height: 100%;

  .title {
    font-size: 2em;
    font-weight: 700;
    color: $dark;
  }

  .subTitle {
    font-size: 1.2em;
    font-weight: 600;
    color: $light-shade-darker;
  }
}
