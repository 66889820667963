@import "src/theme";

.home-page-section {
  @include FlexColumn(flex-start, flex-start, 1rem, 0 10%);
  width: 100%;
  height: 100%;
  min-height: unset;

  .header {
    @include FlexRow(flex-start, flex-start, 0.5rem, 0);
    width: 100%;
    padding-bottom: 1rem;

    &_content {
      @include FlexColumn(flex-start, flex-start, 0.5rem, 0);
    }

    .title {
      font-size: 2rem;
      font-weight: 600;
      width: 100%;
    }

    .subTitle {
      font-size: 1.5em;
      font-weight: 500;
      color: RGBA($dark, 0.5);
    }

    @include LessThanTablet {
      flex-direction: column;

      .title {
        font-size: 1.25em;
      }

      .subTitle {
        font-size: 1em;
      }
    }
  }

  .content {
    width: 100%;
  }

  @include LessThanMidLaptop {
    padding: 0 3rem;
  }

  @include LessThanTablet {
    padding: 0 1rem;
  }
}
