@import 'src/theme';


@mixin ThemeMixin($color: $primary) {
	.icon {
		color: $color;
	}

	.vertical-line {
		background-color: $color;
	}

	.text-content {
		.title {
			color: $color;
		}
	}
}

.container {
	position: fixed;
	right: 3rem;
	bottom: 2rem;
	max-width: 50%;
	transition: all 0.25s ease-in-out;
	transform: scale(0);
	transform-origin: right;
	opacity: 0;

	@include LessThanLaptop {
		right: 5vw;
		max-width: 90%;
	}

	&.show {
		transform: scale(1);
		opacity: 1;

		.vertical-line {
			animation: widthzero calc(var(--duration) * 1s) linear;
		}
	}

	.notification {
      @include FlexRow(flex-start, center, 1rem, 0.5rem 1rem 0.75rem);
		position: relative;
		overflow: hidden;
		width: 100%;
		height: 100%;
		border-radius: 0.25rem;background-color: $light;
		box-shadow: 0 1px 8px RGBA($accent-rgb, 0.1);

		&.success {
			@include ThemeMixin($success);
		}

		&.warning {
			@include ThemeMixin($warning);
		}

		&.danger {
			@include ThemeMixin($primary);
		}

		.vertical-line {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 3px;
			border-radius: 50rem;
			background-color: $accent;
		}

		.icon {
			color: $accent;
		}

		.icon.close {
			cursor: pointer;
			color: $light-shade-darker;
		}

		.text-content {
          @include FlexColumn(flex-start, flex-start, 0.25rem, 0);

			.title {
				font-size: 1.1rem;
			}

			.message {
				font-size: 0.9rem;
			}
		}
	}
}

@keyframes widthzero {
	from {
		width: 100%;
	}
	to {
		width: 0;
	}
}
