@import 'src/theme';

.company-strip {
  width: 100%;
  height: 100%;

  .title-small {
    font-size: 2rem;
    font-weight: 700;
    line-height: 56px;
    width: 100%;
  }

  .text {
    font-size: 1.5rem;
    line-height: 28px;
    width: 100%;
  }


  .company-container {
    @include FlexRow(center, center, 2rem 3rem, 2rem);
    overflow: hidden;
    flex-wrap: wrap;
    width: 100%;
    list-style: none;
    transition: all 0.125s ease-in-out;

    @include LessThanLaptop {
      padding: 2rem 0;
      gap: 1rem;

      .company-logo {
        width: 125px;
        max-height: 125px;
      }
    }
  }

  .company {
    &:hover {

      .company-logo {
        $scale: 1.1;
        transform: scale3d($scale, $scale, $scale);
      }
    }
  }

  .company-logo {
    width: 150px;
    height: 100%;
    transition: all $x-fast $basic-curve;
    object-fit: contain;
  }

}
