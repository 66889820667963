@import 'src/theme/variables';
@import 'src/theme/mixin';

.get-in-touch {
  @include FlexRow(center, center, 1em, 1em);
	margin: 1rem 2rem;
	color: $light;
	border-radius: 0.5rem;
	background-color: $primary;

	@include LessThanLaptop {
		margin: 1rem 2rem;
	}
}
