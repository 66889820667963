@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import 'theme';
@import "react-responsive-carousel/lib/styles/carousel.min.css";

* {
	font-family: 'Roboto', sans-serif;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

html, body, #root {
	width: 100%;
	height: 100%;
}

html {
	scroll-behavior: smooth;
}
