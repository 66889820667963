@import "src/theme";

.email-area {
  width: 80%;
  margin: auto;
  border: 1px solid black;

  .email-template {
    width: 100%;
    height: 100%;

  }
}
