@import 'src/theme/common';
@import 'src/theme/mixin';
@import 'src/theme/variables';


.home-hero {
  position: relative;
  width: 100%;
  height: 100%;

  .hero {
    position: relative;
    width: 100%;
    height: 100%;
    @include FlexRow(flex-start, center, 0, 10%);
    min-height: calc(100vh - 114px);

    @include LessThanLaptop {
      height: 80vh;
      min-height: 80vh;
    }

    .text-content-section {
      max-width: 75%;
      @include FlexColumn(flex-start, flex-start, 5rem);
      text-align: left;

      @include LessThanLaptop {
        max-width: 100%;
      }

      &_main {
        width: 100%;
        @include FlexColumn(flex-start, flex-start, 2rem, 0);

        @include LessThanLaptop {
          justify-content: center;
          gap: 2rem;
        }

        @include LessThanTablet {
          gap: 1rem;
        }

        .preTitle {
          font-size: 2rem;
          font-weight: 600;
          color: $primary;

          @include LessThanLaptop {
            font-size: 1.75rem;
          }

          @include LessThanTablet {
            font-size: 1.5rem;
          }

        }

        .title {
          font-size: 2.5rem;
          font-weight: 600;
          line-height: 2.75rem;
          color: $dark;

          @include LessThanLaptop {
            font-size: 2rem;
            line-height: 2.25rem;
          }

          @include LessThanTablet {
            font-size: 1.75rem;
            line-height: 2rem;
          }

        }
      }

      .cta-button-link {
        @include LessThanTablet {
          width: 100%;
        }
      }
    }

    @include LessThanLaptop {
      justify-content: center;
      padding: 1rem;
    }

    .hero-image-section {
      position: absolute;
      z-index: -1;
      right: 0;
      overflow: hidden;
      width: 60%;
      height: 100%;

      &:before {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 120%;
        height: 120%;
        content: '';
        background: linear-gradient(357.98deg, #ffffff 9.42%, rgba(255, 255, 255, 0) 50.04%), linear-gradient(89.94deg, #ffffff 0.05%, rgba(0, 0, 0, 0) 100%);
      }

      @include LessThanLaptop {
        width: 100%;
        height: 100%;
        filter: grayscale(1) blur(2px);

        &:before {
          background: RGBA($light-rgb, 0.7);

        }
      }

      .hero-image {
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }


  }

  .action {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 1rem;
    display: grid;
    width: 48px;
    height: 48px;
    cursor: pointer;
    user-select: none;
    transform: translateY(-50%);
    border-radius: 50%;
    background: $light;
    box-shadow: 0 1px 8px RGBA($dark-rgb, 0.05);
    place-items: center;

    @include LessThanTablet {
      display: none;
    }

    &:hover {
      box-shadow: 0 2px 8px RGBA($dark-rgb, 0.15);
    }

    &:active {
      outline: 2px solid $dark;
      outline-offset: 2px;
    }

    &-right {
      right: 2rem;
      left: unset;
    }
  }
}
