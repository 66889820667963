@import "src/theme";

.product-list-page {

  .header {

    @include LessThanTablet {

      .search-bar {
        width: 100%;
      }
    }
  }

  .text-content {
    .title {
      padding-bottom: 0.5rem;
    }

    .subtitle {
      font-size: 1.2rem;
      font-weight: 600;
      color: RGBA($dark, 0.5);
    }
  }

  .content {
    position: relative;
    width: 100%;
  }

  .product-list {
    display: grid;
    list-style: none;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 2em;
  }

  .search-bar {
    @include FlexRow(space-between, center, 0.5rem, 0);
    position: relative;
    overflow: hidden;
    padding-right: 8px;
    border: 2px solid $dark;
    border-radius: 0.5rem;

    input {
      font-size: 1.1rem;
      padding: 8px 12px;
      border: 0;

      &:active, &:focus, &:focus-visible, &:focus-within {
        border: none;
        outline: none;
      }
    }
  }

  select {
    font-size: 16px;
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 40px;
    padding: 1em;
    transition: background-color 0.125s ease-in-out;
    border: none;
    background: RGBA($light-shade-darker-rgb, 0.15);

    &::placeholder {
      font: $light-shade-darker;
    }

    &:active, &:focus, &:focus-within, &:focus-visible {
      outline: none;
      background-color: RGBA($primary-shade-rgb, 0.1);
    }
  }
}
