@import 'src/theme/mixin';
@import 'src/theme/variables';

@mixin Solid-Fill($color, $backgroundColor) {
	color: $color;
	background-color: $backgroundColor;

	&:hover {
		//box-shadow: 0 2px 4px RGBA($backgroundColor, 0.5);
	}
}

@mixin Outline-Fill($color) {
	@include Fill($color);
	border: 1px solid $color;
}

@mixin Fill($color) {
	color: $color;

	&:hover {
		background-color: RGBA($color, 0.2);
	}
}

button {
	&.btn {
      @include FlexRow(center, center, 0.5em, 0.5em 1.5em);
		font-size: 1em;
		font-weight: 500;
		cursor: pointer;
		transition: all 0.25s ease-in-out;
		white-space: nowrap;
		letter-spacing: 1px;
		border: none;
		border-radius: 0;
		outline: none;
      background-color: transparent;

		&:hover {
			background-color: $light-shade
		}
	}

	&.link {
		padding-right: 0;
		padding-left: 0;
	}

	&.small {
		font-size: 12px;
		padding: 0.25em 0.75em;
	}

	&.primary {
		@include Fill($primary);
	}

	&.accent {
		@include Fill($accent);
	}

	&.light {
		@include Fill($light);
	}

	&.pill {
		//border-radius: 500rem;
	}

	&[disabled] {
		cursor: not-allowed;
		color: $light-shade-darker;
	}

	&:before {
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
		content: '';
		transition: all 0.125s ease-in-out;
		opacity: 0;
		border-radius: inherit;
		background-color: RGBA($light-shade, 0.25);
		scale: 0;
	}

	&.solid {
		@include Solid-Fill($dark, $light-shade-darker);

		&.primary {
			@include Solid-Fill($light, $primary);
		}

		&.accent {
			@include Solid-Fill($light, $accent);
		}

		&.light {
			@include Solid-Fill($accent, $light);
		}

		&[disabled] {
			@include Solid-Fill($light, $light-shade-darker);
		}
	}

	&.cta {
		font-size: 1.15rem;
		border-radius: 50rem;
	}

	&.outline {
		@include Outline-Fill($light-shade-darker);

		&.primary {
			@include Outline-Fill($primary);
		}

		&.accent {
			@include Outline-Fill($accent);
		}

		&.light {
			@include Outline-Fill($light);
		}

		&[disabled] {
			@include Outline-Fill($light-shade-darker);
		}
	}

	&.icon-only {
		padding: 0.5em;
		border-radius: 50%;
	}

	&.large {
		font-size: 1.25rem;
		padding: 1rem 2rem;
	}
}
