@import 'src/theme';

.product-category-card {
  @include FlexColumn(flex-start, flex-start, 1rem, 1rem);
  width: 100%;
  height: 100%;
  outline: 1px solid $light-shade-darker;

  &_image-section {
    width: 100%;
    height: 100%;
  }

  &_image {
    width: 100%;
    aspect-ratio: 1;
    object-fit: scale-down;
    object-position: center;
  }

  &_content {
    flex: 1 1 auto;
  }

  &_title {
    font-size: 1.5rem;
    color: $dark;
  }

  &_actions-section {
    @include FlexRow(flex-start, center, 1rem, 0);
    width: 100%;
  }

  &_main-cta {
    width: 100%;
  }
}
