@import 'src/theme/index';

.service-card {
  @include FlexColumn(flex-start, flex-start, 0, 0);
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	transition: all 0.25s ease-in-out;
	text-align: left;
	border: 1px solid $light-shade-darker;
	background-color: $light;


	.image-area {
		position: relative;
		width: 100%;
		height: 150px;
	}

	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	h2 {
		font-weight: 600;
		color: $dark;
	}

	.content {
      @include FlexColumn(space-between, flex-start, 0.5em, 1em);
		flex: 1 1 auto;
		width: 100%;
	}

	.cta {
		&.primary {
			width: 100%;

			button {
				width: 100%;
			}
		}
	}

	.short-description {
		flex: 1 1 auto;
		padding-bottom: 1rem;
	}

	.description-loader {
		width: 100%;
	}

	.btn {
		padding: 0.5em;
	}
}
