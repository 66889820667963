@import 'src/theme/mixin';
@import 'src/theme/variables';


nav {
	position: sticky;
	z-index: 3;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $light;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);

	.row {
		@include FlexRow(flex-start, center, 0, 0);
		width: 100%;
		height: 6vh;


		@include LessThanLaptop {
			&:last-child {
				//height: 0;
			}
		}

		&.top {
			width: 100%;
			height: fit-content;
			padding: 8px 0;
			background: $accent;

			.content {
				width: 80%;
				margin: auto;

				@include LessThanDesktop {
					width: calc(100% - 3rem);
				}
			}
		}
	}

	.navbar {
		@include FlexColumn(flex-start, flex-start, 0.5rem, 0.5rem 0);
		width: 80%;
		height: 100%;
		margin: auto;

		@include LessThanDesktop {
			width: calc(100% - 3rem);
		}

		.company-logo, .logo, .logo a {
			height: 75%;
		}

		.logo, .logo a {
			@include FlexRow(center, center, 0, 0)
		}

		.company-logo {
			min-height: 40px;
			max-height: 90px;
		}

		.links {
			@include FlexRow(flex-start, center, 0.5em);
			height: 100%;
			list-style: none;

			@include LessThanMidLaptop {
				gap: 0.5em;
			}

			@include LessThanLaptop {
				@include FlexColumn(center, center, 2em, 2em);
				position: fixed;
				z-index: 2;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				transition: all 0.25s ease-in-out;
				transform: translateX(100%);
				background-color: $light;

				&.open {
					transform: translateX(0);
				}
			}

			.nav-cta {
				@include LessThanMidLaptop {
					.phone-text, .email-text {
						display: none;
					}
				}

				@include LessThanLaptop {
					.phone-text, .email-text {
						display: block;
					}
				}

				&.laptop {
					@include LessThanLaptop {
						display: none;
					}
				}

				&.mobile {
					@include MoreThanTablet {
						display: none;
					}
				}
			}

			@include LessThanLaptop {
				.spacer {
					flex: 0 0 auto;
				}
			}

			.link {
				@include FlexRow(center, center, 1em, 0.5em 1em);
				font-size: 1.25em;
				font-weight: 500;
				cursor: pointer;
				transition: all 0.125s ease-in-out;
				text-decoration: none;

				&.active {
					font-weight: 600;
					color: $primary;
				}

				&:hover {
					background-color: RGBA($light-shade, 1);
				}

				&:active {
					background-color: RGBA($light-shade-darker, 0.5);
				}

				@include LessThanLaptop {
					position: relative;
					overflow: hidden;
					width: 100%;
					padding: 2em 0;

					&:before {
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: 2px;
						content: '';
						transition: all 0.25s ease-in-out;
						transform: translateX(-100%);
						background-color: $primary;
					}

					&.active:before, {
						transform: translateX(0);
					}

					&:hover {
						background-color: RGBA($primary-rgb, 0.1);
					}
				}
			}
		}

		.mobile-menu-icon {
			z-index: 3;
			@include MoreThanTablet {
				display: none;
			}
		}
	}

	.top-links {
		@include FlexRow(flex-start, center, 1rem, 0);

		.link {
			font-size: 1.25rem;
			font-weight: 500;
			padding: 0.125rem 1rem;
			cursor: pointer;
			color: $dark;
			border-radius: 0;
			background: $light;
		}
	}

	.search-bar {
		@include FlexRow(space-betwee, center, 0.5rem, 0);
		position: relative;
		overflow: hidden;
		padding-right: 8px;
		border: 2px solid $dark;
		border-radius: 0.5rem;

		input {
			font-size: 1.1rem;
			padding: 8px 12px;
			border: 0;

			&:active, &:focus, &:focus-visible, &:focus-within {
				border: none;
				outline: none;
			}
		}
	}
}
