@import 'variables';
@import 'mixin';

.card {
  @include FlexColumn(flex-start, flex-start, 0, 1em);
	border-radius: 0.5em;
	background-color: $light-shade;

	&-title {
		font-size: 20px;
		font-weight: 600;
		letter-spacing: 0.05em;
		color: $accent;
	}

	&-image {
		width: 100%;
		height: 150px;
		border-radius: 0.25em;
		object-fit: cover;
	}

	&-text {
		font-size: 16px;
		font-weight: 500;
		letter-spacing: 0.05em;
	}

	&-content {
		flex-grow: 1;
	}
}
