@import 'src/theme';
@import "animate.css";

.app {
	position: relative;
	overflow-x: hidden;
	overflow-y: auto;
	height: 100%;
}

main {
	@include FlexColumn(flex-start, flex-start, 0, 0);

	@include LessThanLaptop {
		width: 100%;
		margin-left: 0;
	}
}

* {
	//border: 1px solid;
}


.book-service-call {
	position: fixed;
	right: 3rem;
	bottom: 3rem;

	@include LessThanLaptop {
		right: 2rem;
		bottom: 2rem;
	}

	@include LessThanTablet {
		right: 1rem;
		bottom: 1rem;
		transform: scale3d(0.75, 0.75, 0.75);
	}

	.content {
		position: relative;
		display: inline-flex;
		overflow: hidden;
		align-items: center;
		width: auto;
		max-width: 4rem;
		height: 4rem;
		padding: 1rem;
		cursor: pointer;
		transition: max-width 0.25s ease-in-out, transform 0.125s ease-in-out;
		color: $light;
		border-radius: 500px;
		background-color: $primary;
		box-shadow: 0 1px 8px RGBA($accent-rgb, 0.25);

		&:hover {
			max-width: 210px;

			.text {
				opacity: 1;
			}
		}

		&:before {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: '';
			border-radius: 500px;
			background-color: RGBA($primary-rgb, 0.5);
		}

		&:active {
			transform: scale(0.9);
		}

	}


	.text {
		transition: all 0.25s ease-in-out;
		white-space: nowrap;
		opacity: 0;
	}

	.icon {
		display: flex;
		align-items: center;
		margin-right: 8px;
	}
}


@keyframes highlight {
	from {
		transform: scale(0.5);
	}
	to {
		transform: scale(2);
		background-color: transparent;
	}
}
