@import 'src/theme/index';

.services-section {
	width: 100%;

	.services-cards {
		display: grid;
		width: 100%;
		height: 100%;
		list-style: none;
		grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
		grid-template-rows: auto;
		grid-gap: 3em;
	}


	.title-small {
		font-size: 2rem;
		font-weight: 700;
		line-height: 56px;
		width: 100%;
	}

	.text {
		font-size: 1.5rem;
		line-height: 28px;
		width: 100%;
	}
}
