@import "src/theme";

.dropdown-selector {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  outline: none;

  &.open-up {
    .options {
      top: auto;
      bottom: 100%;
    }
  }

  .selected-option {
    @include FlexRow(flex-start, center, 0.5rem, 1rem);
    cursor: pointer;
    transition: border-color 0.3s, color 0.3s, background-color 0.3s;
    border-radius: 4px;
    background: RGBA($light-shade-darker-rgb, 0.15);

    &:hover {
      border-color: $primary; /* Darker red on hover */
    }

    .chevron-icon {
      transition: all 0.125s ease-in-out;

      &.open {
        transform: rotateZ(180deg);
      }
    }
  }

  .options {
    position: absolute;
    top: 125%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto; /* Enable vertical scroll if needed */
    width: 100%;
    max-height: 200px; /* Set a maximum height for the dropdown list */
    margin: 0;
    padding: 0;
    list-style: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

    li {
      padding: 1rem;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background-color: RGBA($primary-shade-rgb, 0.15); /* Light blue on hover */
      }

      &.selected {
        font-weight: 600;
        color: $light;
        background: RGBA($primary, 1);
      }

      &.disabled {
        cursor: not-allowed; /* Change cursor for disabled options */
        opacity: 0.5; /* Reduced opacity for disabled options */
      }
    }
  }

  /* Define scrollbar styles */
  $scrollbar-width: 10px;
  $scrollbar-thumb-color: RGBA($primary-rgb, 0.75); /* Red */
  $scrollbar-track-color: $light; /* White */
  $scrollbar-track-background: $light-shade; /* Light gray for the track */
  $border-radius: 5px;

  /* Apply styles to the scrollbar */
  ::-webkit-scrollbar {
    width: $scrollbar-width;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background-color: $scrollbar-thumb-color;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: $border-radius;
    background-color: $scrollbar-track-background;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: darken($scrollbar-thumb-color, 10%);
  }

  /* Handle when dragged */
  ::-webkit-scrollbar-thumb:active {
    background-color: darken($scrollbar-thumb-color, 20%);
  }


}
