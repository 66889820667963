$primary: #e31837;
$primary-shade: #ad122a;
$accent: #222327;
$dark: #000000;
$light: #ffffff;
$light-shade-darker: #b2b2b4;
$light-shade: #f2f2f2;

$primary-rgb: rgb(227, 24, 55);
$primary-shade-rgb: rgb(173, 18, 42);
$accent-rgb: rgb(34, 35, 39);
$dark-rgb: rgb(0, 0, 0);
$light-rgb: rgb(255, 255, 255);
$light-shade-darker-rgb: rgb(178, 178, 180);
$light-shade-rgb: rgb(242, 242, 242);

$success: #28ba62;
$success-rgb: rgb(40, 186, 98);

$warning: #e0ac08;
$warning-rgb: rgb(224, 172, 8);

$laptopBreakpoint: 1366px;
$tabletBreakpoint: 768px;
$mobileBreakpoint: 576px;
$midLaptopBreakpoint: 1024px;

// TRANSITION CURVES
$basic-curve: cubic-bezier(0.4, 0.0, 0.2, 1);

// TRANSITION TIMING
$x-fast: 0.125s;
$fast: 0.25s;
$normal: 0.5s;
$slow: 1s;
$x-slow: 1.25s
