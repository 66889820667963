@import "../../theme/index";

.product-details-page {
  &-details {
    @include FlexColumn(flex-start, flex-start, 1rem, 0);

    @include MoreThanTablet {
      gap: 2em;
    }
  }

  .product {
    &-main {
      display: grid;
      padding: 3rem 0 1rem;
      grid-template-columns: 1fr;
      grid-gap: 1em;

      @include MoreThanTablet {
        grid-template-columns: clamp(400px, 50%, 100%) 1fr;
        grid-template-rows: 1fr;
      }
    }

    &-data {
      @include FlexColumn(flex-start, flex-start, 2em, 0);

      .add-to-cart-button {
        @include LessThanTablet {
          width: 100%;
        }
      }
    }

    &-meta {
      @include FlexColumn(flex-start, flex-start, 1em, 0);
      width: 100%;
    }

    &-description {
      ul {
        margin-left: 1rem;
      }
    }

    &-data-table {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-template-rows: 1fr;
      grid-column-gap: 2em;
      grid-row-gap: 1em;
      place-items: center flex-start;

      .label {
        font-weight: 600;
        color: $accent;
      }
    }

    &-variants {
      @include FlexRow(flex-start, center, 1em, 1em 0);
      flex-wrap: wrap;
      width: 100%;
    }

    &-variant_selector {
      @include FlexRow(center, center, 0, 0.5em 1em);
      font-weight: 500;
      width: fit-content;
      height: 100%;
      list-style: none;
      cursor: pointer;
      text-wrap: nowrap;
      border: 2px solid RGBA($accent-rgb, 0.5);
      background: RGBA($light-shade, 0.1);

      &.selected {
        font-weight: 500;
        color: $light;
        background: RGBA($accent-rgb, 0.5);
      }
    }

    &-table {
      width: 100%;
      margin-bottom: 3rem;

      thead, tr > td:first-of-type {
        font-size: 1.1rem;
        font-weight: 600;
        background: RGBA($light-shade-darker, 0.5);
      }

      tr {
        border-bottom: 1px solid $accent-rgb;

        &:hover {
          background: RGBA($light-shade-darker, 0.15);
        }
      }

      td {
        padding: 1em;
        text-align: center;

        &.small {
          width: 10px;
        }
      }

      @include LessThanLaptop {
        margin-bottom: 2rem;
      }
    }
  }

  .controls {
    @include FlexRow(flex-start, center, 0.5em, 0);

    .control {
      width: 30px;
      height: 30px;
      cursor: pointer;
      border: none;
      background: RGBA($light-shade-darker-rgb, 0.2);

      &:hover {
        background: RGBA($light-shade-darker-rgb, 0.5);
      }

      &:active {
        background: RGBA($light-shade-darker-rgb, 0.75);
      }
    }

    .input-value {
      font-weight: 600;
      max-width: 100px;
      height: 30px;
      padding: 0 0.5em;
      border: 2px solid RGBA($accent-rgb, 0.5);
      outline: none;
    }
  }

}

.horizontal-divider {
  width: 90%;
  height: 2px;
  margin: auto;
  border-radius: 100em;
  background: RGBA($light-shade-darker-rgb, 0.5);
}

.product-details {
  @include FlexColumn(flex-start, flex-start, 0, 0);
  width: 100%;
  height: 100%;

  &__image {
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
}
