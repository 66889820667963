@import 'src/theme';

.product-card {
	display: flex;
	flex-flow: column nowrap;
	width: 100%;
	height: 100%;
	padding: 1em;
	border: 1px solid $light-shade-darker;
	background: $light;

	.thumbnail {
		width: 100%;
		height: 240px;
		cursor: pointer;
		object-fit: contain;
		object-position: center;
	}

	.text-content {
		@include FlexColumn(flex-start, flex-start, 0.5em, 0.5em 0 0 0);
		flex: 1 1 auto;

		.meta-content {
			cursor: pointer;
		}

		.title {
			font-size: 1.25em;
			width: 100%;
		}

		.category-name {
			font-size: 1em;
			font-weight: 600;
			color: $light-shade-darker;
		}

		.cta {
			width: 100%;
			margin-top: 0.5em;

			.details-button {
				flex: 3 1 auto;

				button {
					width: 100%;
				}
			}
		}
	}
}
