@import "src/theme";

.tab-bar {
  @include FlexRow(flex-start, flex-start, 0, 0);
  width: 100%;
  list-style: none;
  border-bottom: 2px solid $light-shade-darker;

  @include LessThanTablet {
    flex-direction: column;
    border-bottom: none;
  }

  .tab {
    @include FlexRow(center, center, 0.25em, 0.5em 1.5em);
    font-size: 1.2em;
    flex: 1 1 auto;
    cursor: pointer;
    color: $accent;
    border: none;
    background: RGBA($light-shade-darker-rgb, 0.15);

    @include LessThanTablet {
      width: 100%;
      border-bottom: 2px solid $light-shade-darker;
    }

    &.active {
      font-weight: 600;
      color: $light;
      border-color: $accent;
      background: $accent;
    }
  }
}

.tab-content {
  width: 100%;
  height: 100%;
}
